export const faqData = [{
    faqQuestionHeader: 'Will you make a custom quilt for me?',
    faqAnswer: "Probably! It depends on how busy I am and how complex of a quilt you are looking for, but I would love the opportunity to hear what you'd like to do! The easiest way is to Contact Me and we can get started on your idea!",
    id: 1
}, {
    faqQuestionHeader: 'How long does it take to make a quilt?',
    faqAnswer: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum porro assumenda quod similique. Deleniti quas obcaecati autem adipisci possimus sit ducimus facere corrupti ipsum tempora? Voluptates, velit cum. Labore, cumque? ',
    id: 2
}, {
    faqQuestionHeader: 'Do you ship internationally?',
    faqAnswer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorem? Adipisci explicabo exercitationem esse nulla, mollitia voluptatem cupiditate commodi excepturi nemo tempora aliquam blanditiis maxime?',
    id: 3
}]
